var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.products.length
    ? _c(
        "div",
        { staticClass: "product-detail row no-gutters" },
        [
          _c("context-title", {
            staticClass: "col-12",
            attrs: { title: _vm.title, passedActions: _vm.titleBarActions },
          }),
          _c("key-val-line", { attrs: { prop: "Enabled" } }, [
            _vm._v(
              _vm._s(_vm.prettyBoolean(_vm.product.inventoryItemAvailable))
            ),
          ]),
          _c("key-val-line", { attrs: { prop: "Type" } }, [_vm._v("Food")]),
          _c("key-val-line", { attrs: { prop: "Category" } }, [
            _vm._v(_vm._s(_vm.category)),
          ]),
          _c("key-val-line", { attrs: { prop: "Cost" } }, [
            _vm._v(_vm._s(_vm.product.costDisplay)),
          ]),
          _vm.product.inventoryItemSubs.length === 1
            ? [
                _c("key-val-line", { attrs: { prop: "UPC Code" } }, [
                  _vm._v(
                    " " + _vm._s(_vm.product.inventoryItemSubs[0].sku) + " "
                  ),
                ]),
              ]
            : [
                _c("key-val-line", { attrs: { prop: "UPC Code" } }, [
                  _vm._v(" Unavailable due to multiple sizes on item "),
                ]),
              ],
          _c("key-val-line", { attrs: { prop: "Description" } }, [
            _vm._v(_vm._s(_vm.product.inventoryItemDescription)),
          ]),
          _c("key-val-line", { attrs: { prop: "Alcohol" } }, [
            _vm._v(_vm._s(_vm.product.bAlcohol ? "Yes" : "No")),
          ]),
          _c("key-val-line", { attrs: { prop: "Employee Only" } }, [
            _vm._v(_vm._s(_vm.product.bEmployeeItem ? "Yes" : "No")),
          ]),
          _c(
            "key-val-line",
            { attrs: { prop: "Tax (Default)" } },
            [
              _c("toggle", {
                staticClass: "d-inline-block block-xs--md block-xs--xs-right",
                attrs: { disabled: true, value: _vm.product.taxRate },
              }),
              _vm._v(
                " Default (" +
                  _vm._s(_vm.parseTaxRate(_vm.product.taxRate)) +
                  "%) "
              ),
            ],
            1
          ),
          _vm.product.inventoryItemSubs.length > 1
            ? _c(
                "key-val-line",
                { attrs: { prop: "Item Variations" } },
                [
                  _c("variation-list", {
                    attrs: {
                      struct: _vm.variationStruct,
                      variations: _vm.sortedSubs,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("key-val-line", { attrs: { prop: "Tipping" } }, [_vm._v(" Off ")]),
          _c("key-val-line", { attrs: { prop: "Platform" } }, [
            _vm._v(
              " " +
                _vm._s(_vm.spreadItems(["Mobile", "Kiosk", "Table Top"])) +
                " "
            ),
          ]),
          _c("key-val-line", { attrs: { prop: "Options" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.spreadItems(["Available on mobile", "Featured item"])
                ) +
                " "
            ),
          ]),
          _c("key-val-line", { attrs: { prop: "Time" } }, [
            _vm._v(" " + _vm._s(_vm.startEndTime) + " "),
          ]),
          _c("key-val-line", { attrs: { prop: "Mod Sets" } }, [
            _vm._v(" (NEEDS WORK) "),
          ]),
          _c(
            "key-val-line",
            { attrs: { prop: "Mod Groups" } },
            _vm._l(_vm.product.modGroups, function (modGroup) {
              return _vm.product.modGroups.length
                ? _c("div", { key: modGroup.title }, [
                    _vm._v(" " + _vm._s(modGroup.title) + " "),
                  ])
                : _vm._e()
            }),
            0
          ),
          _vm.product.inventoryTags.length
            ? _c("key-val-line", { attrs: { prop: "Attributes" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.spreadItems(_vm.product.inventoryItemAttributes)
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.product.inventoryTags.length
            ? _c("key-val-line", { attrs: { prop: "Tags" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.spreadItems(
                        _vm.product.inventoryTags.map(
                          (tag) => tag.tagDescription
                        )
                      )
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { staticClass: "row no-gutters" },
              [
                _c(
                  "key-val-line",
                  { attrs: { prop: "Image" } },
                  [
                    _c("image-block", {
                      attrs: {
                        images: [
                          this.imageServer + _vm.product.inventoryItemImageName,
                        ],
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }