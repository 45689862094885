var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "row block-xs--xs-bottom" },
        _vm._l(_vm.struct, function (prop) {
          return _c("div", { key: prop, staticClass: "col" }, [
            _c("b", [_vm._v(_vm._s(prop))]),
          ])
        }),
        0
      ),
      _vm._l(_vm.variations, function (variation) {
        return _c(
          "div",
          { key: variation, staticClass: "row" },
          _vm._l(_vm.struct, function (prop, key) {
            return _c("div", { key: prop, staticClass: "col" }, [
              _vm._v(" " + _vm._s(variation[key]) + " "),
            ])
          }),
          0
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }