<template>
    <div>
        <div class="row block-xs--xs-bottom">
            <div 
                v-for="prop in struct" 
                :key="prop" 
                class="col">
                <b>{{ prop }}</b>
            </div>
        </div>

        <div 
            v-for="variation in variations" 
            :key="variation" 
            class="row">
            <div 
                v-for="(prop, key) in struct" 
                :key="prop" 
                class="col">
                {{ variation[key] }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        variations: {
            type: Array,
            required: true,
        },
        struct: {
            type: Object,
            required: true,
        },
    },
}
</script>
