<template>
    <div 
        v-if="products.length" 
        class="product-detail row no-gutters">
        <context-title
            :title="title"
            :passedActions="titleBarActions"
            class="col-12" />

        <key-val-line prop="Enabled">{{ prettyBoolean(product.inventoryItemAvailable) }}</key-val-line>

        <key-val-line prop="Type">Food</key-val-line>

        <key-val-line prop="Category">{{ category }}</key-val-line>

        <key-val-line prop="Cost">{{ product.costDisplay }}</key-val-line>

        <template v-if="product.inventoryItemSubs.length === 1">
            <key-val-line prop="UPC Code">
                {{ product.inventoryItemSubs[0].sku }}
            </key-val-line>
        </template>

        <template v-else>
            <key-val-line prop="UPC Code">
                Unavailable due to multiple sizes on item
            </key-val-line>
        </template>

        <key-val-line prop="Description">{{ product.inventoryItemDescription }}</key-val-line>

        <key-val-line prop="Alcohol">{{ product.bAlcohol ? 'Yes' : 'No' }}</key-val-line>

        <key-val-line prop="Employee Only">{{ product.bEmployeeItem ? 'Yes' : 'No' }}</key-val-line>

        <key-val-line prop="Tax (Default)">
            <toggle 
                :disabled="true" 
                :value="product.taxRate" 
                class="d-inline-block block-xs--md block-xs--xs-right" />
            Default ({{ parseTaxRate(product.taxRate) }}%)
        </key-val-line>

        <key-val-line 
            v-if="product.inventoryItemSubs.length > 1" 
            prop="Item Variations">
            <variation-list 
                :struct="variationStruct" 
                :variations="sortedSubs" />
        </key-val-line>

        <key-val-line prop="Tipping">
            Off
        </key-val-line>

        <key-val-line prop="Platform">
            {{ spreadItems(['Mobile', 'Kiosk', 'Table Top']) }}
        </key-val-line>

        <key-val-line prop="Options">
            {{ spreadItems(['Available on mobile', 'Featured item']) }}
        </key-val-line>

        <key-val-line prop="Time">
            {{ startEndTime }}
        </key-val-line>

        <key-val-line prop="Mod Sets">
            (NEEDS WORK)
        </key-val-line>

        <key-val-line prop="Mod Groups">
            <div
                v-for="modGroup in product.modGroups"
                v-if="product.modGroups.length" 
                :key="modGroup.title"
            >
                {{ modGroup.title }}
            </div>
        </key-val-line>

        <key-val-line 
            v-if="product.inventoryTags.length" 
            prop="Attributes">
            {{ spreadItems(product.inventoryItemAttributes) }}
        </key-val-line>

        <key-val-line 
            v-if="product.inventoryTags.length" 
            prop="Tags">
            {{ spreadItems(product.inventoryTags.map(tag => tag.tagDescription)) }}
        </key-val-line>

        <div class="col-12">
            <div class="row no-gutters">
                <key-val-line prop="Image">
                    <image-block :images="[this.imageServer + product.inventoryItemImageName]" />
                </key-val-line>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { spreadItems, getDateRange, dateStringToTime } from 'helpers'
import { ProductNavigationController } from '../mixins'
import lineItem from '../components/line_item.vue'
import keyValLine from '../components/key_val_line.vue'
import variationList from '../components/variation_list.vue'
import contextTitle from '../components/context_title.vue'
import toggle from '../components/cleverly/Toggle.vue'
import imageBlock from '../components/image_block.vue'

export default {
    name: 'StoreProduct',
    
    mixins: [ProductNavigationController],

    computed: {
        sortedSubs() {
            return this.product.inventoryItemSubs
                .sort((a, b) => {
                    const aPrice = parseFloat(a.price || a.cost)
                    const bPrice = parseFloat(b.price || b.cost)

                    if (aPrice > bPrice) {
                        return 1
                    }

                    if (aPrice < bPrice) {
                        return -1
                    }

                    return 0
                })
                .map(variation => {
                    const sku = variation.pos_sizeID || variation.sku

                    return {
                        ...variation,
                        sku,
                    }
                })
        },
        title() {
            return [
                {to: {name: 'store-products'}, label: 'Products'},
                this.product.inventoryItemName,
            ]
        },
        startEndTime() {
            return getDateRange(dateStringToTime(this.product.startTimeLocal), dateStringToTime(this.product.endTimeLocal))
        },
        product() {
            const productId = this.$route.params.product

            return this.products.find(el => (el.inventoryItemID === productId))
        },
        category() {
            return this.product.inventoryTitles[0].inventoryTitleDescription
        },
        titleBarActions() {
            const edit = {
                type: 'edit',
                display: 'Edit',
                run: () => {
                    const productId = this.product.inventoryItemID

                    this.$router.push({
                        name: 'store-product-edit',
                        params: {product: productId},
                    })
                },
            }

            const next = {
                type: 'next',
                display: 'View Next Item',
                run: () => {
                    this.gotoNextItem(this.product.inventoryItemID)
                },
            }

            if (this.hasNextItem(this.product.inventoryItemID)) {
                return [edit, next]
            }

            return [edit]
        },
        ...mapState({
            imageServer: state => state.imageServer,
        }),
        ...mapGetters([
            'products',
        ]),
    },
    data() {
        return {
            variationStruct: {
                inventoryItemSubName: 'Name',
                cost: 'Price',
                sku: 'Item ID',
            },
            images: [
                {path: 'https://placeholdit.imgix.net/~text?txtsize=28&bg=EC407A&txt=300%C3%97300&w=300&h=300'},
                {path: 'https://placeholdit.imgix.net/~text?txtsize=28&bg=EC407A&txt=300%C3%97300&w=300&h=300'},
                {path: 'https://placeholdit.imgix.net/~text?txtsize=28&bg=EC407A&txt=300%C3%97300&w=300&h=300'},
            ],
        }
    },
    watch: {
        // '$route.params.product'() {
        //     this.baseProduct = false
        // },
        products() {
            this.resolvePageTitle()
        },
    },
    created() {
        this.resolvePageTitle()
    },
    methods: {
        prettyBoolean(bool) {
            return bool ? 'Yes' : 'No'
        },
        parseTaxRate(rate) {
            if (rate !== '0') {
                return rate * 100
            }

            return rate
        },
        resolvePageTitle() {
            if (this.products.length) {
                document.title = this.product.inventoryItemName
            } else {
                document.title = 'Product'
            }
        },
        spreadItems,
        getDateRange,
    },
    components: {
        lineItem,
        keyValLine,
        variationList,
        contextTitle,
        toggle,
        imageBlock,
    },
}
</script>

<style lang="scss">
@import '~scss/mixins.scss';
@import 'bootstrap/scss/mixins/_grid.scss';

.product-detail {
    &__line {
        min-height: height(12);
        align-items: center;
        padding: spacing(sm);
    }
}
</style>
